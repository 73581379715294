<template>
  <div>
    <section class="section">
      <div class="container mt-60">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4">
            <library :content.sync="content" />
          </div>
          <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8">
            <div class="section-title">
              <div class="media features">
                <div class="icons m-0 rounded h1 text-primary px-3">
                  <i class="uil uil-notes"></i>
                </div>
              </div>
              <h5 class="title">จัดการเนื้อหาประเภทข้อสอบ (Examination)</h5>
              <p class="border-bottom"></p>
              <h5 class="text-primary">ประเภทตัวเลือกเดียว (Single choice)</h5>
            </div>

            <div class="row justify-content-center">
              <div class="col-12">
                <b-tabs
                  pills
                  justified
                  content-class="mt-4 pt-2"
                  nav-wrapper-class=""
                >
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">เพิ่มคำถาม</p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดปุ่ม "New Question"</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal1>
                          <img
                            src="/images/screen/course/exam/singleChoice/add_1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal1"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/add_1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เลือกประเภทคำถาม "Single Choice"
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal2>
                          <img
                            src="/images/screen/course/exam/singleChoice/add_2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal2"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/add_2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">3</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เพิ่มข้อมูลในแบบฟอร์มคำถาม
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal3>
                          <img
                            src="/images/screen/course/exam/singleChoice/add_3.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal3"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/add_3.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">4</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "save" บันทึกข้อมูล
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal4>
                          <img
                            src="/images/screen/course/exam/singleChoice/add_4.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal4"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/add_4.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">แก้ไขคำถาม</p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "edit" หลังคำถามที่ต้องการแก้ไข
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal5>
                          <img
                            src="/images/screen/course/exam/singleChoice/edit_1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal5"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/edit_1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">แก้ไขข้อมูลในแบบฟอร์ม</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal6>
                          <img
                            src="/images/screen/course/exam/singleChoice/edit_2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal6"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/edit_2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">3</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "save" บันทึกข้อมูล
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal7>
                          <img
                            src="/images/screen/course/exam/singleChoice/edit_3.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal7"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/edit_3.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">ลบคำถาม</p>
                      </div>
                    </template>

                    <div class="row pl-3">
                      <h6>การลบคำถามมี 2 แบบ ดังนี้</h6>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              การลบคำถามข้อเดียว เริ่มจากกดปุ่ม "delete"
                              หลังคำถามที่ต้องการลบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal8>
                          <img
                            src="/images/screen/course/exam/singleChoice/del_1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal8"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/del_1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "confirm" ยืนยันการลบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal9>
                          <img
                            src="/images/screen/course/exam/singleChoice/del_2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal9"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/del_2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">3</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              การลบคำถามหลายข้อ เริ่มจาก
                              เลือกคำถามที่ต้องการจะลบ โดยกดคลิ้กที่หน้าข้อคำถาม
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal10>
                          <img
                            src="/images/screen/course/exam/singleChoice/del_3.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal10"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/del_3.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">4</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดที่ปุ่มไอคอนถังขยะ เพื่อทำการลบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal11>
                          <img
                            src="/images/screen/course/exam/singleChoice/del_4.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal11"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/del_4.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">5</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "confirm" ยืนยันการลบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal12>
                          <img
                            src="/images/screen/course/exam/singleChoice/del_5.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal12"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/del_5.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <div class="text-center pt-1 pb-1">
                        <p class="title font-weight-normal mb-0">นำเข้าคำถาม</p>
                      </div>
                    </template>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">1</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">กดปุ่ม "Import Exam"</h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal13>
                          <img
                            src="/images/screen/course/exam/singleChoice/import_1.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal13"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/import_1.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">2</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เลือกประเภทคำถาม "Single Choice"
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal14>
                          <img
                            src="/images/screen/course/exam/singleChoice/import_2.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal14"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/import_2.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">3</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "Download Sample"
                              เพื่อดูตัวอย่างแบบฟอร์มการนำเข้าข้อสอบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal15>
                          <img
                            src="/images/screen/course/exam/singleChoice/import_3.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal15"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/import_3.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">4</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดเลือกไฟล์เพื่อนำเข้าข้อสอบ
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal16>
                          <img
                            src="/images/screen/course/exam/singleChoice/import_4.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal16"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/import_4.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">5</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              เมื่อนำเข้าข้อสอบแล้ว รายการคำถามจะปรากฏด้านล่าง
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal17>
                          <img
                            src="/images/screen/course/exam/singleChoice/import_5.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal17"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/import_5.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12 mt-2">
                        <div class="media features mb-3">
                          <div
                            class="icon text-center rounded-circle text-primary mr-3"
                          >
                            <h3 class="mb-0">6</h3>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="title mb-0">
                              กดปุ่ม "Confirm Importing" บันทึกข้อมูล
                            </h4>
                          </div>
                        </div>
                        <a href="javascript: void(0);" v-b-modal.modal18>
                          <img
                            src="/images/screen/course/exam/singleChoice/import_6.png"
                            class="img-fluid mx-auto rounded-md shadow-lg d-block"
                          />
                        </a>
                        <b-modal
                          id="modal18"
                          hide-footer
                          hide-header
                          size="xl"
                          header-close-variant="white"
                          header-class="border-0"
                          content-class="border-0"
                          centered
                        >
                          <img
                            src="/images/screen/course/exam/singleChoice/import_6.png"
                            class="img-fluid mx-auto shadow-lg d-block"
                          />
                        </b-modal>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import library from "@/views/library/library.vue";
export default {
  data() {
    return {
      content: "lectureExamSingleChoice",
    };
  },
  components: {
    library,
  },
};
</script>
